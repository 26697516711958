<template>
  <f7-block class="photo-block-container">
    <img :src="image" alt=""  v-if="image">
    <div class="photo-block-inner" v-else>
      <f7-icon f7="camera"></f7-icon>
      <span>{{title}}</span>
      <input ref="file_input" type="file" accept="image/*" class="file-input" @change="uploadImage($event)">
    </div>
    <div class="outer-events">
      <f7-preloader v-if="preloader" color="primary"/>
      <f7-button v-if="repeat_upload" icon-f7="arrow_clockwise" @click="repeatUpload"/>
      <f7-icon v-if="upload_success" f7="checkmark_alt" color="green" bg-color="sub-btn"/>
    </div>
  </f7-block>
</template>

<script>
import {f7} from "framework7-vue/bundle";
export default {
  name: "PhotoBlock",
  props: ['title', 'type', 'photo_id'],
  data() {
    return {
      image: '',
      preloader: false,
      repeat_upload: false,
      upload_success: false,
    }
  },
  methods: {
    uploadImage(e) {
      this.repeat_upload = false
      this.preloader = true
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      this.$axios.post(`https://${this.$server.domain}/crmAPI/file/upload?token=${this.$server.getToken()}`, formData)
          .then(data => {
            this.upload_success = true
            this.image = data.data.response
            if (this.type === 'selfie') this.$eventBus.emit('upload-selfie', data.data.response)
            if (this.type === 'control_photos') this.$eventBus.emit('upload-control-photos', {url: data.data.response, id: this.photo_id})
            this.preloader = false
          })
          .catch(e => {
            this.preloader = false
            this.repeat_upload = true
            f7.sheet.create({
              el:
                  `
                    <div
                    class="error-sheet sheet-modal"
                    >
                        <div class="block-title sheet-title">Отсутствует подключение к интернету.</div>
                        <div class="sheet-subtitle">Попробуйте позже</div>
                        <div class="button color-main-btn sheet-close">Ок</div>
                    </div>
                    `,
              backdrop: true,

            }).open()
          })
    },
    repeatUpload() {
      this.$refs.file_input.click()
    }
  }
}
</script>

<style scoped lang="scss">
.photo-block-container {
  width: 152px;
  height: 152px;
  background: var(--element-color);
  border-radius: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  img {
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
  }
  .photo-block-inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--sub-text-color);
    font-size: 12px;
    position: relative;
    padding: 0 25px;
  }
  span {
    text-align: center;
  }
  .file-input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }
  .outer-events {
    position: absolute;
    top: 0;
    right: -40px;
  }
}
</style>
