<template>
  <f7-sheet :opened="sheetOpened"
            close-by-outside-click
            class="exit-sheet"
            backdrop
  >
    <f7-block-title class="sheet-title">Вы точно хотите выйти?</f7-block-title>
    <f7-block class="display-flex">
      <f7-button fill color="sub-btn" sheet-close>Нет</f7-button>
      <f7-button fill color="main-btn"
                 sheet-close
                 @click="exit"
      >Да</f7-button>
    </f7-block>
  </f7-sheet>
</template>

<script>
import {f7} from "framework7-vue/bundle";
export default {
  name: "Exit",
  props: ['sheetOpened'],
  methods: {
    exit() {
      this.$server.setToken('')
      localStorage.clear()
      this.$server.request('auth/logout')
      f7.views.current.router.navigate('/auth/')
      this.$eventBus.emit('load-user')
    }
  }
}
</script>

<style scoped lang="scss">
.exit-sheet {
  .sheet-title {
    margin-bottom: 32px;
  }
}
</style>
