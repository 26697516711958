<template>
  <f7-page no-swipeback>
    <img src="/img/credit_card_icon.png" width="66" alt="" class="credit-card-icon">
    <f7-block-title class="order-page-title">Поздравляем! Вы завершили выполнение заказа</f7-block-title>
    <f7-block class="total-container">
      <div class="total-title">Будет начислено за заказ:</div>
      <div class="total-container-inner">
        {{$store.state.order.salary}} {{$currency}}
      </div>
    </f7-block>
    <div class="button-container">
      <f7-button color="main-btn" popup-close @click="$eventBus.emit('load-orders', ['future', 'past'])">Посмотреть еще заказы</f7-button>
      <f7-button color="sub-btn" popup-close @click="openProfile">В свой профиль</f7-button>
    </div>
  </f7-page>
</template>

<script>
import {f7} from "framework7-vue/bundle";
export default {
  name: "Complete",
  methods: {
    openProfile() {
      f7.tab.show('#view-profile')
    },
  },
  mounted() {
    f7.navbar.hide('.navbar-order')
  },

}
</script>

<style scoped lang="scss">
.credit-card-icon {
  margin-bottom: 40px;
}
.total-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  background: var(--element-color);
  padding-top: 14px;
  padding-bottom: 32px;
  .total-title {
    text-align: center;
    width: 100%;
    color: var(--sub-text-color);
    font-size: 15px;
    text-transform: uppercase;
    padding-bottom: 14px;
    border-bottom: 1px dashed var(--sub-text-color)
  }
  .total-container-inner {
    padding-top: 24px;
    font-size: 32px;
  }
}
</style>
