<template>
  <f7-sheet :opened="sheetOpened"
            close-by-outside-click
            class="set-vocation-sheet"
            backdrop
            @sheet:opened="opened"
  >
    <f7-block class="sheet-vocation-inner">
      <f7-row class="row-option">
        <f7-col>
          <div class="row-title">Рабочий день</div>
          <div class="row-subtitle">В выбранный день у вас будут заказы</div>
        </f7-col>
        <f7-col>
          <f7-button
              v-if="!vocationChecked"
              color="main-btn" icon-f7="checkmark_alt"
              sheet-close
              @click="set(1)"
          />
          <f7-button color="sub-btn" v-else
                     sheet-close
                     @click="set(0)"
          >Выбрать</f7-button>
        </f7-col>
      </f7-row>
      <f7-row class="row-option">
        <f7-col>
          <div class="row-title">Выходной</div>
          <div class="row-subtitle">Не получать заказов в этот день</div>
        </f7-col>
        <f7-col>
          <f7-button
              v-if="vocationChecked"
              color="main-btn" icon-f7="checkmark_alt"
              @click="set(0)"
          />
          <f7-button color="sub-btn" v-else
                     @click="set(1)"
                     :disabled="vocationDisabled"
          >Выбрать</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-sheet>
</template>

<script>
import {f7} from "framework7-vue/bundle";

export default {
name: "setVocation",
  data() {
  let today = new Date()
    return {
      sheetOpened: false,
      vocationChecked: false,
      vocationDisabled: false,
      date: '',
      today,
    }
  },
  methods: {
    set(value) {
      this.$server.request(`user/updateVocations`, {date: this.date, setVocation: value}, ()=>{
        this.workDayChecked = !value
        this.$eventBus.emit('schedule-sheet-closed', {date: this.date})
        f7.sheet.close('.set-vocation-sheet')
      })
    },
    opened() {
    },
    closed() {
      this.$eventBus.off('day-click')
    }
  },
  created() {
    this.$eventBus.on('day-click', (data)=>{
      this.date = data.date
      this.vocationChecked = data.value

      this.vocationDisabled = Math.ceil((new Date(data.date).getTime() - this.today.getTime()) / (1000 * 3600 * 24)) < this.$store.state.vocationDelay;
    })
  }
}
</script>

<style scoped lang="scss">
.sheet-vocation-inner {
  .row-option {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .button {
      width: 100px;
      margin-left: auto;
    }
    .row-title {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .row-subtitle {
      font-size: 14px;
      color: var(--sub-text-color);
    }
  }
}
</style>
