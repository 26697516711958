<template>
  <f7-popup class="popup-order">
    <f7-view :url="`/order/${f7route.params.id}/${f7route.params.not_confirmed}/preview`" name="order_view">
      <f7-navbar no-hairline class="navbar-order">
        <f7-nav-left>
          <f7-link icon-f7="xmark" popup-close></f7-link>
        </f7-nav-left>
        <f7-nav-title class="navbar-order-progress">
          <f7-progressbar :progress="10" class="order-progress"></f7-progressbar>
        </f7-nav-title>
      </f7-navbar>
    </f7-view>
  </f7-popup>
</template>

<script>
export default {
  name: "OrderView",
  props: {
    f7route: Object
  },
  data() {
    return {
      order: {}
    }
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.navbar-order-progress {
  width: 100px;
}
</style>
