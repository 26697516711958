<template>
  <f7-page no-swipeback @page:afterout="pageout">
    <f7-block-title class="order-page-title">Выгул</f7-block-title>
    <div class="order-page-subtitle">Отлично, приступайте! Будьте внимательны на заказе, желаем Вам удачи!</div>
    <f7-block class="timer-container">
      <div class="timer-title">Таймер выполнения:</div>
      <div class="timer-container-inner">{{`${h}:${min}:${sec}`}}</div>
    </f7-block>
    <div class="button-container">
      <f7-button color="main-btn" @click="finish" :loading="loading"
                 preloader
                 preloader-size="26"
      >Завершить</f7-button>
      <f7-button color="sub-btn" :href="`/order/${$store.state.order.id}/0/fullOrderStat`" popup-open>Информация о заказе</f7-button>
    </div>
  </f7-page>
</template>

<script>
import {f7} from "framework7-vue/bundle"

export default {
  name: "Timer",
  props: {
    f7route: Object,
  },
  data() {
    return {
      h: '00',
      min: '00',
      sec: '00',
      timerInterval: null,
      loading: false
    }
  },
  methods: {
    finish() {
      this.loading = true
      this.$server.request(`orders/setProgress/${this.f7route.params.id}`, {progress: 3}, ()=>{
        this.loading = false
        f7.views.current.router.navigate(`/order/${this.f7route.params.id}/0/control_photos`)
        f7.progressbar.set('.order-progress', 75)
      }, ()=>this.loading = false)
    },
    startTimer() {
      let sec = 0
      if (this.$store.state.order.datetime_start*1) sec = this.$store.state.order.current_datetime*1 - this.$store.state.order.datetime_start*1
      this.timerInterval = setInterval(() => this.showTime(++sec), 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval)
    },
    showTime(sec) {
      this.min = parseInt(sec / 60) % 60;
      this.h = parseInt(sec / 3600) % 60;
      this.sec = sec % 60;
      if (this.sec < 10) this.sec = '0' + this.sec;
      if (this.min < 10) this.min = '0' + this.min;
      if (this.h < 10) this.h = '0' + this.h;
    },
    pageout() {
      this.stopTimer()
    }
  },
  created() {
    console.log('store order',this.$store.state.order)
    this.startTimer()
  },
}
</script>

<style scoped lang="scss">
.timer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  background: var(--element-color);
  padding-top: 14px;
  padding-bottom: 32px;
  .timer-title {
    color: var(--sub-text-color);
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  .timer-container-inner {
    font-size: 32px;
  }
}
</style>
