<template>
  <f7-page class="offices">
    <f7-navbar no-hairline class="offices-navbar">
      <f7-nav-left></f7-nav-left>
      <f7-nav-title style="width: 100%; left: 0">
        <f7-searchbar
            search-container=".search-list"
            placeholder="Ваш офис"
            search-in=".item-title"
            disable-button-text="Отмена"
            no-hairline
            no-shadow
        ></f7-searchbar>
      </f7-nav-title>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Офис не найден"></f7-list-item>
    </f7-list>
    <f7-list class="search-list searchbar-found offices-list"
             no-hairlines
    >
      <f7-list-item
          v-for="office in offices"
          :key="office.id"
          :title="office.address"
          :link="`/auth/phone/${office.id}`"
          @click="navbarHide"
      >
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import {useStore} from 'framework7-vue/bundle';
import store from "@/store";
import {f7} from 'framework7-vue/bundle'

export default {
  name: "Offices",
  data() {
    const offices = useStore(store, 'offices');
    return {
      offices,
    }
  },
  methods: {
    navbarHide() {
      f7.navbar.hide('.offices-navbar')
    }
  }
}
</script>

<style scoped lang="scss">
.offices-list {
  .title {
    left: 0 !important;
    width: 100% !important;
  }
}
.offices {
  .offices-list {
    width: 100%;
  }
}
</style>
