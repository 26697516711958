<template>
  <f7-page no-toolbar class="schedule-page">
    <NavbarPage title="Мой график"/>
    <f7-block-title class="schedule-title">Укажите комфортное время работы</f7-block-title>
    <f7-block class="calendar-container" ref="schedule_calendar">
      <div class="schedule-calendar"></div>
    </f7-block>
    <div class="schedule-subtitle">Помните, что выходной день можно назначить не ранее, чем через 3 дня.</div>
    <div class="schedule-badges">
      <span class="work-badge">
        <f7-badge color="sub-btn"></f7-badge> - Рабочий день
      </span>
      <span class="vocation-badge">
        <f7-badge color="main-btn"></f7-badge> - Выходной
      </span>
    </div>
    <setVocation/>
  </f7-page>
</template>

<script>
import NavbarPage from "@/components/NavbarPage";
import {f7} from "framework7-vue/bundle";
import setVocation from "@/components/sheets/setVocation";

export default {
  name: "Schedule",
  components: {
    NavbarPage,
    setVocation
  },
  data() {
    let today = new Date()
    let firstDayCurrMonth = new Date(today.getFullYear(), today.getMonth(), 2).toISOString().slice(0, 10)
    let lastDayCurrMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1).toISOString().slice(0, 10)
    return {
      vocations: [],
      firstDayCurrMonth,
      lastDayCurrMonth,
    }
  },
  methods: {
    get(from, to) {
      this.$server.request('user/getVocations', {from: from, to: to}, (data) => {
        data.response.forEach(date => {
          let day = parseInt(date.split('-')[2])
          let month = parseInt(date.split('-')[1]) - 1
          let year = parseInt(date.split('-')[0])
          this.$refs.schedule_calendar.$el.querySelector(`.calendar-month-current [data-date="${year}-${month}-${day}"]`).classList.add('day-weekend')
        })
        this.vocations = data.response
      })
    },
  },
  mounted() {
    let that = this;
    f7.calendar.create({
      containerEl: '.schedule-calendar',
      value: [new Date()],
      weekHeader: true,
      locale: 'ru',
      disabled: {
        from: '2010-01-01',
        to: new Date(Date.now() - 86400000)
      },
      events: [],
      renderToolbar: () => {
        return `
        <div class="calendar-month-selector">
          <a class="link icon-only calendar-prev-month-button">
              <i class="icon icon-prev"></i>
          </a>
          <a class="current-month-value link"></a>, <a class="current-year-value link"></a>
          <a class="link icon-only calendar-next-month-button">
              <i class="icon icon-next"></i>
          </a>
        </div>`
      },
      on: {
        init(c) {
          let currentMonth = new Date(c.value)
          that.firstDayCurrMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 2).toISOString().slice(0, 10)
          that.lastDayCurrMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1).toISOString().slice(0, 10)
          that.get(that.firstDayCurrMonth, that.lastDayCurrMonth)
        },
        monthYearChangeStart(calendar) {
          that.firstDayCurrMonth = new Date(calendar.currentYear, calendar.currentMonth, 2).toISOString().slice(0, 10)
          that.lastDayCurrMonth = new Date(calendar.currentYear, calendar.currentMonth + 1, 1).toISOString().slice(0, 10)
          that.get(that.firstDayCurrMonth, that.lastDayCurrMonth)
        },
        dayClick(c, dayEl, year, month, day) {
          month = month + 1;
          if (month < 10) month = '0' + month;
          if (day < 10) day = '0' + day;
          that.$eventBus.emit('day-click', {
            date: `${year}-${month}-${day}`,
            value: that.vocations.includes(`${year}-${month}-${day}`)
          })
          f7.sheet.create({
            el: '.set-vocation-sheet'
          }).open()
        },
      }
    })
  },
  created() {
    this.$eventBus.on('schedule-sheet-closed', (data) => {
      let year = parseInt(data.date.split('-')[0])
      let month = parseInt(data.date.split('-')[1]) - 1
      let day = parseInt(data.date.split('-')[2])
      let date = `${year}-${month}-${day}`
      this.$refs.schedule_calendar.$el.querySelector(`.calendar-month-current [data-date="${date}"]`).classList.remove('day-weekend')
      this.get(this.firstDayCurrMonth, this.lastDayCurrMonth)
    })
  }
}
</script>

<style lang="scss">
.schedule-page {
  .page-content {
    padding-top: 0;
  }
  .schedule-title {
    margin-top: 16px;
    margin-bottom: 32px;
    text-align: center;
  }

  .calendar-container {
    margin-bottom: 16px;

    .schedule-calendar {
      .calendar-month-selector {
        margin-bottom: 8px;

        .current-month-value, .current-year-value {
          font-size: 18px;
          text-transform: capitalize;
        }

        .current-year-value {
          margin-left: 8px;
        }
      }

      .calendar-month-current {
        .calendar-day:not(.calendar-day-prev):not(.calendar-day-next):not(.day-weekend) {
          span {
            background: var(--sub-btn-bg);
            color: white;
          }
        }

        .calendar-day.day-weekend {
          span {
            color: var(--f7-text-color);
            background: var(--main-btn-bg) !important;
          }
        }

        .calendar-day.calendar-day-today {
          span.calendar-day-number {
            background: var(--f7-text-color);
            color: var(--sub-text-color)
          }
        }
      }
    }
  }

  .schedule-subtitle {
    margin-bottom: 8px;
  }

  .schedule-badges {
    text-align: center;

    .work-badge {
      margin-right: 8px;
    }

    .badge {
      width: 30px;
      height: 30px;
      position: relative;
    }
  }
}
</style>
