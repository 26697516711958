import {f7} from "framework7-vue/bundle";

export class Server {
    constructor() {
        this.domain = 'pet.khromov.su';
        this.url = `https://${this.domain}/execAPI/`;
        this.token = this.getToken();
    }

    getToken() {
        return localStorage.getItem('api_token');
    }

    setToken(token) {
        localStorage.setItem('api_token', token)
        this.token = token;
    }

    serialize(obj, prefix) {
        var str = [];
        for (var p in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, p)) {
                var k = prefix ? prefix + '[' + p + ']' : p,
                    v = obj[p];
                str.push(typeof v == 'object' ? this.serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }
        }
        return str.join('&');
    }

    request(method, data = {}, onSuccess, onError, onFinally) {
        let formData = new FormData()
        if (this.token) data['token'] = this.token
        for (let i in data) {
            formData.append(i, data[i]);
        }

        const checkForError = (response) => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        };

        return fetch(`${this.url + method}`, {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
            },
        })
            .then(checkForError)
            .then((response) => {
                if (!response.success && response.error === 'user is not logged in') {
                    // this.logout();
                    return;
                }
                if (!response.success && typeof onError === 'function') return onError(response);
                if (response.success && typeof onSuccess === 'function') onSuccess(response);
                return response;
            })
            .catch((error) => {
                if (typeof onError === 'function') onError(error);
                console.log('error',error)
                f7.sheet.create({
                    el:
                        `
                    <div
                    class="error-sheet sheet-modal"
                    >
                        <div class="block-title sheet-title">Отсутствует подключение к интернету.</div>
                        <div class="sheet-subtitle">Попробуйте позже</div>
                        <div class="button color-main-btn sheet-close">Ок</div>
                    </div>
                    `,
                    backdrop: true,

                }).open()
                return error;
            })
            .finally((loading) => {
                if (loading) loading = false;
                if (typeof onFinally === 'function') onFinally();
            });
    }
}
