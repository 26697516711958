<template>
  <f7-page class="login-screen-preview">
    <f7-navbar no-hairline>
      <f7-nav-title><img src="/img/favicon.png" width="48"></f7-nav-title>
    </f7-navbar>
    <f7-card class="card-preview" no-border no-shadow
             color="element-color"
    >
      <f7-card-header class="card-preview-header">
        <img src="/img/photo_preview.png" alt="">
      </f7-card-header>
      <f7-card-content class="card-preview-content">
        Какой-то текст для исполнителей
      </f7-card-content>
    </f7-card>
    <f7-button color="main-btn" href="/auth/offices">Войти</f7-button>
    <p class="offer-text">Нажимая «Войти», вы соглашаетесь условиями <a href="#">оферты</a></p>
  </f7-page>
</template>

<script>
export default {
  name: "Preview",
  props: {
    f7route: Object
  },
  methods: {
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.login-screen-preview {
  position: relative;
  .card-preview {
    border-radius: 16px;
    margin-bottom: 55px;
    margin-top: 20px;
    &-header {
      justify-content: center;
      height: 192px;
      img {
        height: 100%;
      }
    }
    &-content {
      text-align: center;
      font-size: 13px;
    }
  }
  .offer-text {
    position: absolute;
    bottom: 12px;
    text-align: center;
    max-width: 215px;
    margin: 0 auto;
    font-size: 12px;
    color: var(--sub-text-color);
  }
}
</style>
