<template>
  <f7-page class="page-orders" @page:tabshow="get(null, 'future', true)">
    <f7-navbar class="navbar-orders" no-hairline inner-class-name="navbar-inner-orders">
      <f7-nav-left class="navbar-orders-title">Заказы</f7-nav-left>
    </f7-navbar>
    <f7-toolbar class="toolbar-orders" top tabbar no-hairline>
      <f7-link tab-link="#to_confirm" class="tab-link-order">Подтверждение {{user.orders_to_confirm}}</f7-link>
      <f7-link tab-link="#future" class="tab-link-order" tab-link-active>Текущие</f7-link>
      <f7-link tab-link="#past" class="tab-link-order">Прошедшие</f7-link>
    </f7-toolbar>
    <f7-tabs swipeable>
      <f7-tab
          v-for="tab in tabs"
          :key="tab.id"
          :id="tab.id" :tab-active="tab.is_active" data-ptr-distance="16"
          :class="`ptr-content-${tab.id}`"
          class="tab-orders page-content ptr-content" @tab:show="tabShow(tab.id)" @tab:hide="tabHide(tab.id)">
        <div class="ptr-preloader">
          <div class="preloader"></div>
          <div class="ptr-arrow"></div>
        </div>
        <OrderCard v-for="order in orders[tab.id]"
                   :key="order.id"
                   :order="order"
                   :tab_id="tab.id"
                   v-if="orders[tab.id].length"
        />
        <div v-else class="empty-order-list">
          <EmptyText text="Список заказов пустой"/>
          <f7-button v-if="tab.id === 'to_confirm'"
                     color="main-btn"
                     @click="get(null, tab.id)"
                     :loading="loading_orders"
                     preloader
                     preloader-size="26"
          >Обновить</f7-button>
        </div>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import OrderCard from "@/components/OrderCard";
import EmptyText from "@/components/EmptyText";
import {f7} from "framework7-vue/bundle";
import { useStore } from 'framework7-vue/bundle';
import store from "@/store";

export default {
  name: "Orders",
  components: {
    OrderCard,
    EmptyText
  },
  data() {
    const user = useStore(store, 'user')
    return {
      user,
      orders: {
        to_confirm: [],
        future: [],
        past: []
      },
      tabs: [
        {id: 'to_confirm', is_active: false, showedCount: 0},
        {id: 'future', is_active: true, showedCount: 0},
        {id: 'past', is_active: false, showedCount: 0},
      ],
      loading_orders: false
    }
  },
  methods: {
    get(ptr = null, tab_id = null, firstTime = null) {
      this.loading_orders = true
      if (firstTime) {
        let ptr = f7.ptr.create(`.ptr-content-${tab_id}`)
        ptr.on('pullEnd', () => {
          this.get(ptr, tab_id)
        })
      }
      this.$server.request('orders/get', {type: tab_id}, (data) => {
        this.loading_orders = false
        this.orders[tab_id] = data.response
        if (ptr) ptr.done()
      }, ()=>{this.loading_orders = false})
    },
    tabShow(tab_id) {
      this.get(null, tab_id)
      let ptr = f7.ptr.create(`.ptr-content-${tab_id}`)
      ptr.on('pullEnd', () => {
        this.get(ptr, tab_id)
      })
    },
    tabHide(tab_id) {
      f7.ptr.get(`.ptr-content-${tab_id}`).destroy()
    }
  },
  created() {
    this.$eventBus.on('load-orders', (data) => {
      data.forEach(type => {
        this.get(null, type)
      })
    })
  }
}
</script>

<style lang="scss">
.page-orders {
  background: var(--element-color);

  .page-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 50px;
    .empty-order-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .button {
        width: 100px;
      }
    }
  }
}

.navbar-orders {
  height: 91px;
  margin-left: 0 !important;
}

.navbar-inner-orders {
  background: #ffffff;
  padding: 32px 24px !important;

  .navbar-orders-title {
    font-size: 27px;
  }
}

.toolbar-orders {
  top: 91px !important;

  .tab-link-order {
    font-size: 14px;
    padding-bottom: 16px;

    &.tab-link-active {
      border-bottom: 1px solid var(--f7-text-color);
    }
  }
}

.card-outer {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

</style>
