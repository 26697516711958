<template>
  <div class="empty-text">
    {{text}}
  </div>
</template>

<script>
export default {
  name: "EmptyText",
  props: ['text']
}
</script>

<style scoped lang="scss">
.empty-text {
  margin-top: 48px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  line-height: 132%;
  margin-bottom: 12px;
}
</style>
