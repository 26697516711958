<template>
  <f7-popup class="full-info-popup">
    <f7-page>
      <f7-navbar no-hairline>
        <f7-nav-left>
          <f7-button icon-f7="xmark" popup-close></f7-button>
        </f7-nav-left>
      </f7-navbar>
      <f7-block-title class="order-page-title">{{ order.type }}</f7-block-title>
      <f7-block class="rows-container">
        <RowOrder
            v-if="order.date"
            :value="order.time"
            :label="parseInt(order.date.split('-')[2]) + ' ' + $monthNamesGenetive[order.date.split('-')[1] - 1]"
            :is-header="true"
        />
        <RowOrder
            :value="order.address ? order.address.address : 'В офисе'"
            label="Адрес"
        />
        <RowOrder
            v-if="order.client"
            :value="order.client.name"
            label="Клиент"
        />
        <RowOrder
            v-if="order.address"
            :value="order.address.station"
            :line_color="order.address.metro_color"
            label="Метро"
        />
        <RowOrder
            v-if="order.animal"
            :value="$store.state.systemParams.animalTypes[order.animal].name"
            label="Животное"
        />
        <RowOrder
            v-if="order.animal"
            :value="$store.state.systemParams.animalTypes[order.animal].size[order.size].name"
            label="Размер животного"
        />
        <RowOrder
            v-if="order.animal"
            :value="$store.state.systemParams.animalTypes[order.animal].hair[order.hair].name"
            label="Шерсть"
        />
        <RowOrder
            v-if="order.price"
            :value="{services: order.services, services_list: order.price.services}"
            label="Услуги"
            :multiple="true"
        />
        <RowOrder
            v-if="order.comment"
            :value="order.comment"
            label="Коммент"
        />
        <RowOrder
            :value="$store.state.systemParams.orderPayment[order.payment_type]"
            label="Способ оплаты"
        />
        <f7-list class="set-route-links" v-if="order.address">
          <f7-list-item external target="_blank" :link="`https://yandex.ru/maps/?text=${order.address.address}`"
                        class="set-route">
            <f7-row class="set-route-inner">
              <f7-col width="33"></f7-col>
              <f7-col width="66">Построить маршрут</f7-col>
            </f7-row>
          </f7-list-item>
        </f7-list>
        <RowOrder
            :value="order.total"
            label="Итого"
            :is-footer="true"
        />
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import RowOrder from "@/components/RowOrder";

export default {
  name: "FullInfoPopup",
  components: {
    RowOrder
  },
  data() {
    return {
      order: this.$store.state.order
    }
  }
}
</script>

<style lang="scss">
.full-info-popup {
  .navbar-inner {
    padding: 0 24px;
  }
  .page-content {
    padding-top: 40px;
  }
}
.set-route {
  &-links {
    margin-bottom: 12px;
  }

  &-inner {
    font-size: 13px;
    flex-grow: 1;
    padding-left: 10px;
  }
}
</style>
