<template>
  <f7-page class="control-photos-page" no-swipeback>
    <f7-block-title class="order-page-title">Контрольные фото</f7-block-title>
    <div class="order-page-subtitle">Поздравляем с завершением выгула!
      Добавьте, пожалуйста, контрольные фото
    </div>
    <f7-block class="item-list-container">
      <div class="item-container"
           v-for="(item, i) in $store.state.systemParams.photoTitles"
           :key="i"
      >
        <span class="item-title">{{ i + 1 }}. {{ item }}</span>
        <PhotoBlock title="Открыть камеру и сделать фото" type="control_photos" :photo_id="i"/>
      </div>
    </f7-block>
    <div class="button-container">
      <f7-button color="main-btn" @click="sendPhotos" :disabled="disabledBtn" :loading="loading"
                 preloader
                 preloader-size="26"
      >Отправить фотографии</f7-button>
    </div>
  </f7-page>
</template>

<script>
import {f7} from "framework7-vue"

import PhotoBlock from "@/components/PhotoBlock";

export default {
  name: "ControlPhotos",
  components: {PhotoBlock},
  props: {
    f7route: Object
  },
  data() {
    let photos = []
    this.$store.state.systemParams.photoTitles.forEach((title) => {
      photos.push({title: title, url: ''})
    })
    return {
      photos,
      disabledBtn: true,
      loading: false
    }
  },
  methods: {
    sendPhotos() {
      this.loading = true
      this.$server.request(`orders/attachPhotos/${this.f7route.params.id}`, {photos: JSON.stringify(this.photos)}, ()=>{
        this.$server.request(`orders/setProgress/${this.f7route.params.id}`, {progress: 4}, ()=>{
          this.loading = false
          f7.progressbar.set('.order-progress', 100)
          f7.views.current.router.navigate(`/order/${this.f7route.params.id}/0/complete`)
        }, ()=>this.loading = false)
      })
    }
  },
  created() {
    this.$eventBus.on('upload-control-photos', (data) => {
      this.photos[data.id].url = data.url
      if (this.photos.every(photo=>photo.url)) this.disabledBtn = false
    })
  }
}
</script>

<style scoped lang="scss">
.item-list-container {
  margin-bottom: 104px;
}

.item-container {
  margin-bottom: 24px;

  .item-title {
    font-size: 15px;
    margin-bottom: 16px;
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
