<template>
  <f7-row class="row-order"
          :class="{'row-order-header': isHeader,
          'row-order-footer': isFooter
          }"
          v-if="value">
    <f7-col width="33" :class="{'header-value': isHeader}" class="row-label">{{ label }}{{isHeader ? '' : ':'}}</f7-col>
    <f7-col
        v-if="multiple"
        width="66">
      <span
          v-for="(count, i) in value.services"
          :key="i"
      >{{value.services_list[i].name}} {{value.services_list[i].type*1===0 ? `${count} ${$store.state.systemParams.units[value.services_list[i].unit]}` : ''}}<br></span>
    </f7-col>
    <f7-col
        v-else
        width="66" :class="{'footer-value': isFooter}">{{value }} {{ isFooter ? $currency : ''}}
      <f7-badge v-if="line_color"
                class="line-color-badge"
                :style="`background-color: ${line_color}`"></f7-badge>
    </f7-col>
  </f7-row>
</template>

<script>
export default {
  name: "RowOrder",
  props: ['isHeader', 'label', 'value', 'isFooter', 'line_color', 'multiple']
}
</script>

<style scoped lang="scss">
.row-order {
  font-size: 13px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 24px;
  }
  &-header {
    margin-bottom: 16px;
  }
  &-footer.row-order {
    padding-top: 12px;
    padding-bottom: 16px;
    margin-bottom: 0;
  }
  .line-color-badge {
    position: relative;
    margin-bottom: 2px;
  }
}
.row-label {
  color: var(--sub-text-color);
}

.header-value {
  color: var(--f7-text-color);
}

.footer-value {
  font-size: 18px;
}
</style>
