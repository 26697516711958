<template>
  <f7-sheet :opened="sheetOpened"
            close-by-outside-click
            class="order-decline-sheet"
            backdrop
            @sheet:closed="closed"
  >
    <f7-block-title class="sheet-title">Объсните причину отказа позвонив в офис</f7-block-title>
    <f7-button fill color="main-btn" sheet-close href="#">Позвонить в офис</f7-button>
  </f7-sheet>
</template>

<script>
import {f7} from "framework7-vue/bundle";

export default {
  name: "OrderDecline",
  data(){
    return {
      sheetOpened: false,
    }
  },
  methods: {
    closed() {
      f7.sheet.destroy('.order-confirm-sheet')
    }
  }
}
</script>

<style scoped lang="scss">
.order-decline-sheet {
  .sheet-title {
    white-space: break-spaces;
  }
}
</style>
