<template>
  <f7-page>
    <f7-block-title class="order-page-title">{{order.type}}</f7-block-title>
    <f7-block class="rows-container">
      <RowOrder
          v-if="order.date"
          :value="order.time"
          :label="parseInt(order.date.split('-')[2]) + ' ' + $monthNamesGenetive[order.date.split('-')[1] - 1]"
          :is-header="true"
      />
      <RowOrder
          :value="order.address ? order.address.address : 'В офисе'"
          label="Адрес"
      />
      <RowOrder
          v-if="order.client"
          :value="order.client.name"
          label="Клиент"
      />
      <RowOrder
          v-if="order.address"
          :value="order.address.station"
          :line_color="order.address.metro_color"
          label="Метро"
      />
      <RowOrder
          v-if="order.animal"
          :value="$store.state.systemParams.animalTypes[order.animal].name"
          label="Животное"
      />
      <RowOrder
          v-if="order.animal"
          :value="$store.state.systemParams.animalTypes[order.animal].size[order.size].name"
          label="Размер животного"
      />
      <RowOrder
          v-if="order.animal"
          :value="$store.state.systemParams.animalTypes[order.animal].hair[order.hair].name"
          label="Шерсть"
      />
      <RowOrder
          v-if="order.price"
          :value="{services: order.services, services_list: order.price.services}"
          label="Услуги"
          :multiple="true"
      />
      <RowOrder
          v-if="order.comment"
          :value="order.comment"
          label="Коммент"
      />
      <RowOrder
          :value="$store.state.systemParams.orderPayment[order.payment_type]"
          label="Способ оплаты"
      />
      <f7-list class="set-route-links" v-if="order.address">
        <f7-list-item external target="_blank" :link="`https://yandex.ru/maps/?text=${order.address.address}`" class="set-route">
          <f7-row class="set-route-inner">
            <f7-col width="33"></f7-col>
            <f7-col width="66">Построить маршрут</f7-col>
          </f7-row>
        </f7-list-item>
      </f7-list>
      <RowOrder
          :value="order.total"
          label="Итого"
          :is-footer="true"
      />
    </f7-block>
    <div
        v-if="f7route.params.not_confirmed === '1'"
        class="button-container">
      <f7-button color="main-btn" @click="confirm">Подтвердить</f7-button>
      <f7-button color="sub-btn" @click="decline">Отклонить</f7-button>
    </div>
    <div class="button-container" v-else>
      <f7-button color="main-btn" @click="start" :loading="start_loading"
                 preloader
                 preloader-size="26"
      >Прибыл на заказ</f7-button>
    </div>
    <OrderConfirm ref="order_confirm_sheet"/>
    <OrderDecline/>
  </f7-page>
</template>

<script>
import RowOrder from "@/components/RowOrder";
import OrderConfirm from "@/components/sheets/OrderConfirm";
import OrderDecline from "@/components/sheets/OrderDecline";
import {f7} from 'framework7-vue/bundle'
export default {
  name: "OrderPreview",
  components: {RowOrder, OrderConfirm, OrderDecline},
  props: {
    f7route: Object
  },
  data() {
    return {
      order: {},
      rows: [
        {value: 'address', label: 'Адрес'},
        {value: 'house', label: 'Дом'},
        {value: 'code', label: 'Домофон'},
        {value: 'type', label: 'Вид услуги'},
        {value: 'metro', label: 'Метро'},
      ],
      start_loading: false,
    }
  },
  methods: {
    get() {
      this.$server.request(`orders/get/${this.f7route.params.id}`,{},(data)=>{
        this.order = data.response
        this.$store.state.order = data.response
        this.checkProgress(this.order.progress)
      })
    },
    checkProgress(progress) {
      if (progress*1 === 1) f7.views.current.router.navigate(`/order/${this.order.id}/0/selfie`)
      else if (progress*1 === 2) f7.views.current.router.navigate(`/order/${this.order.id}/0/timer`)
      else if (progress*1 === 3) f7.views.current.router.navigate(`/order/${this.order.id}/0/control_photos`)
      else if (progress*1 === 4) f7.views.current.router.navigate(`/order/${this.order.id}/0/complete`)
      f7.progressbar.set('.order-progress', 100 - 100 / progress)
    },
    start() {
      this.start_loading = true;
      this.$server.request(`orders/setProgress/${this.order.id}`, {progress: 1}, ()=>{
        this.start_loading = false
        f7.views.current.router.navigate(`/order/${this.order.id}/0/selfie`)
        f7.progressbar.set('.order-progress', 25)
      }, ()=>this.start_loading=false)
    },
    confirm() {
      this.$refs.order_confirm_sheet.open(this.order.id)
    },
    decline() {
      f7.sheet.open('.order-decline-sheet')
    }
  },
  mounted() {
    this.get()
  }
}
</script>

<style scoped lang="scss">
.rows-container {
  padding-bottom: 135px;
}
.set-route {
  &-links {
    margin-bottom: 12px;
  }
  &-inner {
    font-size: 13px;
    flex-grow: 1;
    padding-left: 10px;
  }
}
</style>
