<template>
  <f7-page class="profile-page"
           :page-content="false"
           @page:init="init"
  >
    <f7-page-content class="ptr-content ptr-content-profile">
      <div class="ptr-preloader">
        <div class="preloader"></div>
        <div class="ptr-arrow"></div>
      </div>
      <f7-card
          class="profile-header"
          no-border
          no-shadow
      >
        <f7-card-content>
          <f7-list>
            <f7-list-item media-item>
              <template #media>
                <div class="item-media-inner profile-header-avatar">
                  <img :src="user.photo || '/img/no_avatar.png'" alt="">
                </div>
              </template>
              <template #title>
                <div class="item-title-inner profile-header-username">{{ user.name }}</div>
              </template>
              <template #subtitle>
                <div class="item-subtitle-inner profile-header-rating">
                  <!--                {{ $store.state.user.rating }}-->
                  <f7-icon f7="star_fill" size="12" color="sub-text-color">
                  </f7-icon>
                </div>
              </template>
              <template #after>
                <f7-link
                    href="notifications"
                    icon-only>
                  <f7-icon f7="bell">
                    <f7-badge v-if="user.notifications_count > 0" color="red">{{ user.notifications_count }}</f7-badge>
                  </f7-icon>
                </f7-link>
              </template>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-list no-hairlines
               class="profile-stat"
      >
        <f7-list-item
            class="profile-balance"
            link="balance/">
          <template #header>
            <div class="header-inner">Баланс</div>
          </template>
          <template #footer>
            <div class="footer-inner">{{ user.balance }} {{ $currency }}</div>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-list class="profile-links" no-hairlines no-hairlines-between>
        <f7-list-item class="profile-link" title="Мой график" link="schedule" no-chevron>
          <template #inner-end>
            <f7-icon f7="chevron_right" size="16"></f7-icon>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-button color="sub-text-color" class="justify-content-flex-start" sheet-open=".exit-sheet">Выйти</f7-button>
    </f7-page-content>
    <Exit/>
    <Preview/>
  </f7-page>
</template>

<script>
import {f7} from "framework7-vue/bundle";
import Exit from "@/components/sheets/Exit";
import Preview from "@/pages/auth/View";
import {useStore} from 'framework7-vue/bundle';
import store from "@/store";
import eventBus from "@/eventBus";

export default {
  name: "Profile",
  components: {Exit, Preview},
  setup() {
    const user = useStore(store, 'user');
    return {
      user,
    }
  },
  methods: {
    init() {
      let ptr = f7.ptr.get('.ptr-content-profile')
      ptr.on('pullEnd', () => this.getUser())
    },
    getUser() {
      this.$server.request('user/get', {}, (data) => {
        if (f7.ptr.get('.ptr-content-profile')) f7.ptr.done('.ptr-content-profile')
        let params = {systemParams: data.systemParams};
        if (data.response) params.user = data.response;
        if (!data.response) {
          store.dispatch('set', {offices: data.offices})
        }
        store.dispatch('set', params);
        if (!data.response) eventBus.emit('userNotLoggedIn')
      }, () => {
        if (f7.ptr.get('.ptr-content-profile')) f7.ptr.done('.ptr-content-profile')
      })
    },
  },
  mounted() {
    this.getUser()
  },
  created() {
    this.$eventBus.on('userNotLoggedIn', () => {
      f7.popup.open('.login_screen-popup')
    })
    this.$eventBus.on('load-user', () => this.getUser())
  }
}
</script>

<style lang="scss">
.profile {
  &-page {
    padding-top: 40px;

    .page-content {
      padding-top: 0;
    }
  }

  &-header {
    background: transparent;
    margin-bottom: 32px;
    margin-top: 3px;

    &-username, .rating {
      font-size: 18px;
    }

    &-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &-rating {
      color: var(--sub-text-color);
    }
  }

  &-stat {
    margin-bottom: 40px;
  }

  &-balance {
    background: rgba(244, 240, 237, 0.5);
    border-radius: 16px;

    .item-content {
      height: 85px;
      padding-left: 16px;
      padding-right: 12px;
    }

    .header-inner {
      font-size: 15px;
      color: var(--sub-text-color);
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .footer-inner {
      font-size: 20px;
      color: var(--f7-text-color)
    }
  }

  &-links {
    margin-bottom: 38px;

    .profile-link {
      border-bottom: 1px solid var(--element-color)
    }
  }
}
</style>
