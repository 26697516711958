import { createApp } from 'vue'
// Import F7 Bundle
import Framework7 from 'framework7/bundle'

// Import F7-Vue Plugin Bundle (with all F7 components registered)
import Framework7Vue, { registerComponents, f7 } from 'framework7-vue/bundle'
import 'framework7/framework7-bundle.css'
// Init F7-Vue Plugin
Framework7.use(Framework7Vue);
import 'framework7-icons';
// Import Main App component
import App from './App.vue';
import '@/assets/styles.scss'
import Maska from "maska";
import {Server} from "@/server";
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import $bus from './eventBus'
// Init App
const app = createApp(App);
app.use(Maska)
app.use(VueAxios, axios)
app.config.globalProperties.$currency = '₽'
app.config.globalProperties.$server = new Server()
app.config.globalProperties.$myf7 = f7
app.config.globalProperties.$store = store
app.config.globalProperties.$eventBus = $bus
app.config.globalProperties.$axios = axios
app.config.globalProperties.$monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
app.config.globalProperties.$monthNamesGenetive = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']


// Register all Framework7 Vue components
registerComponents(app);

// Mount Vue App
app.mount('#app');
