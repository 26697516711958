<template>
  <f7-popup class="login_screen-popup">
    <f7-view id="login_screen" url="/auth/preview">
  </f7-view>
  </f7-popup>
</template>

<script>
import NavbarPage from "@/components/NavbarPage";
export default {
  name: "Preview",
  components: {
    NavbarPage
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#login_screen > .page .page-content {
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_screen-popup {
  .page-content {
    padding-top: 60px;
  }
  .auth-title {
    display: flex;
    white-space: break-spaces;
    font-size: 27px;
    line-height: 120%;
    max-width: 230px;
    align-self: flex-start;
  }
  .auth-subtitle {
    font-size: 15px;
    margin-bottom: 52px;
    align-self: flex-start;
  }
  .input {
    width: 100%;
  }
}
</style>
