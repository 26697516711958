<template>
  <f7-app v-bind="f7params" :store="{store}">
    <f7-views tabs>
      <f7-view id="view-profile" tab tab-active url="/profile/"></f7-view>
      <f7-view id="view-orders" tab url="/orders/"></f7-view>
      <f7-toolbar tabbar bottom labels color="#fff" class="main-toolbar">
        <f7-link tab-link="#view-profile" text="Профиль" icon-f7="person_crop_square_fill" tab-link-active
                 icon-size="18"
        ></f7-link>
        <f7-link tab-link="#view-orders" text="Заказы" icon-f7="today"
                 icon-size="18"
        >
          <f7-badge
              v-if="user.orders_to_confirm"
              color="red" class="orders-badge">{{user.orders_to_confirm}}</f7-badge>
        </f7-link>
      </f7-toolbar>
    </f7-views>
  </f7-app>
</template>

<script>
import Profile from "@/pages/Profile";
import Orders from "@/pages/Orders";
import routes from "@/routes";
import store from "@/store";
import { useStore } from 'framework7-vue/bundle';

export default {
  name: 'App',
  components: {Profile, Orders},
  setup() {
    const user = useStore(store,'user');
    return {
      f7params: {
        name: 'Booking pet',
        routes,
      },
      store,
      user
    }
  },
}
</script>

<style>

</style>
