<template>
  <f7-sheet :opened="sheetOpened"
            close-by-outside-click
            class="order-confirm-sheet "
            backdrop
  >
    <f7-block-title class="sheet-title">Вы уверены, что хотите взять заказ?</f7-block-title>
    <f7-block class="display-flex">
      <f7-button fill color="sub-btn" sheet-close>Нет</f7-button>
      <f7-button fill color="main-btn"
                 sheet-close
                 @click="orderConfirm"
      >Да</f7-button>
    </f7-block>
  </f7-sheet>
</template>

<script>
import {f7} from "framework7-vue/bundle";
export default {
  name: "OrderConfirm",
  data() {
    return {
      sheetOpened: false,
      order_id: null,
    }
  },
  methods: {
    orderConfirm() {
      this.$server.request(`orders/confirm/${this.order_id}`, {}, ()=>{
        this.$eventBus.emit('load-orders', ['to_confirm', 'future'])
        this.$eventBus.emit('load-user')
        f7.tab.show('#future')
        f7.popup.close('.popup-order')
        f7.views.current.router.navigate(`/order/${this.order_id}/0/`)
      })
    },
    open(id) {
      this.sheetOpened = true
      this.order_id = id
    },
  }
}
</script>

<style scoped lang="scss">
.order-confirm-sheet {
  .sheet-title {
    margin-bottom: 32px;
  }
}
</style>
