<template>
  <f7-page no-toolbar
           :page-content="false"
           @page:init="init"
  >
    <NavbarPage title="Уведомления"/>
    <f7-page-content class="ptr-content ptr-content-notifications">
      <div class="ptr-preloader">
        <div class="preloader"></div>
        <div class="ptr-arrow"></div>
      </div>
      <f7-messages v-if="notifications"
                   class="notifications-list"
      >
        <f7-message v-for="(notif, i) in notifications"
                    type="received"
                    :key="i"
                    :text="notif.text"
                    :name="notif.datetime.date.split('-').reverse().join('.') + ', ' + notif.datetime.time"
                    :avatar="'/img/no_avatar.png'"
                    class="notification-msg"
                    :first="true"
                    :last="true"
                    :class="{'not-seen': !parseInt(notif.seen)}"
        >
        </f7-message>
      </f7-messages>
      <EmptyText v-else text="У вас пока нет уведомлений."/>
    </f7-page-content>
  </f7-page>
</template>

<script>
import NavbarPage from "@/components/NavbarPage";
import EmptyText from "@/components/EmptyText";
import {f7} from "framework7-vue/bundle";

export default {
  name: "Notifications",
  components: {
    NavbarPage,
    EmptyText,
  },
  data() {
    return {
      notifications: []
    }
  },
  methods: {
    get(ptr = null) {
      this.$server.request('user/notifications', {}, (data) => {
        this.notifications = data.response;
        if (ptr) ptr.done()
        this.$server.request('user/setSeen', {}, () => {
          setTimeout(() => {
            for (let i in this.notifications) this.notifications[i].seen = '1';
          }, 1000);
        })
      }, () => {if (ptr) ptr.done()}
      )
    },
    init() {
      let ptr = f7.ptr.get('.ptr-content-notifications')
      ptr.on('pullEnd', ()=>this.get(ptr))
    }
  },
  mounted() {
    this.get()
  }
}
</script>
<style lang="scss">
.notifications-list {
  min-height: 50%;

  .notification-msg {
    max-width: 100%;
    margin-left: 0;

    &.not-seen {
      .message-bubble {
        background: var(--main-btn-bg);
      }
    }
  }

  .list-item {
    .item-title {
      font-size: 15px;
      white-space: break-spaces;
      line-height: 132%;
    }
  }
}
</style>
