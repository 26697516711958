import Profile from "@/pages/Profile";
import Orders from "@/pages/Orders";
import Notifications from "@/pages/profile_pages/Notifications";
import Balance from "@/pages/profile_pages/Balance";
import Schedule from "@/pages/profile_pages/Schedule";
import Preview from "@/pages/auth/Preview";
import Phone from "@/pages/auth/Phone";
import Code from "@/pages/auth/Code";
import OrderView from "@/pages/order/OrderView";
import OrderPreview from "@/pages/order/Preview"
import Selfie from "@/pages/order/Selfie";
import Timer from "@/pages/order/Timer"
import ControlPhotos from "@/pages/order/ControlPhotos";
import Complete from "@/pages/order/Complete";
import View from "@/pages/auth/View";
import BalanceFullStat from "@/pages/profile_pages/BalanceFullStat";
import FullInfoPopup from "@/pages/order/FullInfoPopup";
import Offices from "@/pages/auth/Offices";
export default [
    {
        path: '/profile/',
        component: Profile,
        routes: [
            {
                path: 'notifications',
                component: Notifications
            },
            {
                path: 'balance/',
                component: Balance,
                routes: [
                    {
                        path: 'fullstat/:month',
                        component: BalanceFullStat
                    }
                ]
            },
            {
                path: 'schedule',
                component: Schedule
            },
        ]
    },
    {
        path: '/orders/',
        component: Orders,
    },
    {
        path: '/auth/',
        popup: {
            component: View,
        },
        routes: [
            {
                path: 'preview',
                component: Preview
            },
            {
                path: 'offices',
                component: Offices
            },
            {
                path: 'phone/:office',
                component: Phone
            },
            {
                path: 'code/:secret_key/:phone/:office',
                component: Code
            },
        ]
    },
    {
        path: '/order/:id/:not_confirmed/',
        name: 'Order',
        popup: {
            component: OrderView
        },
        routes: [
            {
                path: 'preview',
                component: OrderPreview
            },
            {
                path: 'selfie',
                component: Selfie
            },
            {
                path: 'timer',
                component: Timer,
            },
            {
                path: 'control_photos',
                component: ControlPhotos
            },
            {
                path: 'complete',
                component: Complete
            },
            {
                path: 'fullOrderStat',
                popup: {
                    component: FullInfoPopup
                }
            }
        ]
    },
]
