<template>
  <div class="card-outer">
    <f7-card class="card-order" no-shadow
             @click="open"
    >
      <div class="card-received-label" v-if="tab_id === 'past' && order.status*1 === 5">Начислено</div>
      <div class="card-received-label soon" v-else-if="tab_id === 'past'">Скоро начислится</div>
      <f7-card-header class="card-order-header">
        <RowOrder :value="order.time"
                  :is-header="true"
                  :label="parseInt(order.date.split('-')[2]) + ' ' + $monthNamesGenetive[order.date.split('-')[1] - 1]"
        />
      </f7-card-header>
      <f7-card-content class="card-order-content">
        <RowOrder
            :value="order.address ? order.address.address : 'В офисе'"
            label="Адрес"
        />
        <RowOrder
            v-if="order.address.station && tab_id === 'future'"
            :value="order.address.station"
            :line_color="order.address.metro_color"
            label="Метро"
        />

        <RowOrder
            :value="systemParams.animalTypes[order.animal].name"
            label="Животное"
        />
        <RowOrder
            v-if="order.comment && tab_id === 'future'"
            :value="order.comment"
            label="Коммент"
        />
      </f7-card-content>
      <f7-card-footer class="card-order-footer">
        <RowOrder
            :value="order.total"
            label="Итого"
            :is-footer="true"
        />
      </f7-card-footer>
    </f7-card>
  </div>
</template>

<script>
import RowOrder from "@/components/RowOrder";
import {f7, useStore} from "framework7-vue/bundle";
import store from "@/store";
export default {
  name: "OrderCard",
  props: ['order', 'tab_id'],
  components: {RowOrder},
  setup() {
    const systemParams = useStore(store,'systemParams');
    return {
      systemParams
    }
  },
  methods: {
    open() {
      if (this.tab_id === 'to_confirm') f7.views.current.router.navigate({
        name: `Order`,
        params: {id: this.order.id, not_confirmed: 1},
      })
      else if (this.tab_id === 'future') f7.views.current.router.navigate({
        name: `Order`,
        params: {id: this.order.id, not_confirmed: 0},
      })
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
.card-outer {
  padding: 0 24px;
}
.card-order {
  border-radius: 16px;
  padding: 16px;
  width: auto;
  position: relative;
  overflow: hidden;
  .card-received-label {
    position: absolute;
    transform: rotate(40deg);
    height: 20px;
    font-size: 10px;
    text-align: center;
    background: var(--main-btn-bg);
    top: 28px;
    right: -32px;
    width: 160px;
    text-align: center;
    text-transform: uppercase;

    justify-content: center;
    align-items: center;
    display: flex;

    color: #FFFFFF;
    &.soon {
      background: #aaa;
    }
  }
  &-header:after {
    display: none;
  }

  &-content:before, &-content:after {
    display: none;
  }

  &-header, &-footer {
    .row {
      flex-grow: 1;
    }
  }

  &-header, &-content, &-footer {
    padding: 0;
  }
  &-footer {
    padding-top: 12px;
  }
}
</style>
