<template>
  <f7-page>
    <f7-navbar no-hairline>
      <f7-nav-left back-link/>
    </f7-navbar>
    <f7-block-title class="auth-title">Введите код подтверждения</f7-block-title>
    <p class="auth-subtitle">Код отправлен на номер {{ f7route.params.phone }}.
      <f7-link back>Изменить</f7-link>
    </p>
    <!-- eslint-disable -->
    <f7-input
        input-id="input_code"
        ref="input_code"
        outline
        v-model:value="code"
        placeholder="_ _ _ _"
        inputmode="tel"
        autocomplete="off"
        type="tel"
        required
        validate
        :error-message="error_msg"
        :error-message-force="show_error_msg"
        v-maska="'# # # #'"
        @input="checkLength($event); show_error_msg = false"
    ></f7-input>
    <div class="button-container">
      <f7-button color="main-btn"
                 :loading="loading_code"
                 fill
                 @click="auth"
                 preloader
                 preloader-size="26"
      >войти
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import {f7} from "framework7-vue/bundle";
import store from "@/store";
export default {
  name: "Code",
  props: {
    f7route: Object,
  },
  data() {
    return {
      code: '',
      loading_code: false,
      error_msg: '',
      show_error_msg: false
    }
  },
  methods: {
    auth() {
      this.loading_code = true
      this.$server.request('auth/checkCode', {
        code: this.code,
        secret_key: this.f7route.params.secret_key,
        office: this.f7route.params.office
      }, (data) => {
        this.$server.setToken(data.token);
        this.$eventBus.emit('load-user')
        f7.popup.close()
      }, (data) => {
        this.error_msg = data.error
        this.show_error_msg = true
      }, ()=>{
        this.loading_code = false
      })
    },
    checkLength(e) {
      if (e.target.value.length === 7) {
        this.code = e.target.value
        this.auth()
      }
    }
  },
  mounted() {
    this.$refs.input_code.$el.querySelector('#input_code').focus()
  },
}
</script>

<style scoped>

</style>
