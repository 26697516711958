<template>
  <f7-navbar>
    <f7-nav-left back-link></f7-nav-left>
    <f7-nav-title>{{title}}</f7-nav-title>
    <f7-nav-right></f7-nav-right>
  </f7-navbar>
</template>

<script>
export default {
  name: "NavbarPage",
  props: ['title']
}
</script>

<style scoped>

</style>
