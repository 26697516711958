<template>
  <f7-page no-toolbar
           @page:reinit="reinit"
           @page:init="init"
           :page-content="false"
  >
    <NavbarPage title="Баланс"/>
    <f7-page-content class="ptr-content ptr-content-balance">
      <div class="ptr-preloader">
        <div class="preloader"></div>
        <div class="ptr-arrow"></div>
      </div>
      <f7-list v-if="Object.keys(rows).length"
             no-hairlines
             no-hairlines-between
             class="list-balance"
    >
      <f7-list-item
          class="row-balance"
          v-for="(balance, month) in rows"
          :key="month"
          :header="`${$monthNames[parseInt(month.split('-')[1]) - 1]} ${parseInt(month.split('-')[0])}`"
          title="Заработок"
          :after="`${balance.balance} ${$currency}`"
          :link="`fullstat/${month}`"
      />
    </f7-list>
    <EmptyText
        v-else
        text="У вас не было выполнено ни одного заказа, поэтому баланс пустой. Начните выполнять заказ и здесь появиться ваша статистика за 3 месяца."/>
    </f7-page-content>
  </f7-page>
</template>

<script>
import NavbarPage from "@/components/NavbarPage";
import EmptyText from "@/components/EmptyText";
import {f7} from "framework7-vue/bundle";
export default {
  name: "Balance",
  components: {
    NavbarPage,
    EmptyText
  },
  data() {
    return {
      rows: {}
    }
  },
  methods: {
    get(ptr = null) {
      this.$server.request('user/balance', {}, (data)=>{
        this.rows = data.response
        if (ptr) ptr.done()
      }, ()=>{
        if (ptr) ptr.done()
      })
    },
    init() {
      let ptr = f7.ptr.get('.ptr-content-balance')
      ptr.on('pullEnd', ()=>this.get(ptr))
    },
    reinit() {
      f7.toolbar.hide('.main-toolbar')
    }
  },
  mounted() {
    this.get()
  },
}
</script>

<style lang="scss">
.list-balance {
  .row-balance {
    padding: 24px 0 16px;
    border-bottom: 1px solid var(--element-color);
    .item-title {
      font-size: 18px;
      line-height: 18px;
      .item-header {
        font-size: 14px;
        color: var(--sub-text-color);
        margin-bottom: 14px;
      }
    }
    .item-after {
      align-self: flex-end;
      color: var(--f7-text-color);
      line-height: 18px;
    }
  }
}
</style>
