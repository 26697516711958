<template>
  <f7-page no-swipeback>
    <f7-block-title class="order-page-title">Селфи в форме</f7-block-title>
    <div class="order-page-subtitle">Поздравляем с прибытием.
      Спросите у клиента, где можно переодеться и перед тем как приступить к заказу.
    </div>
    <PhotoBlock title="Добавить селфи в форме" type="selfie"/>
    <div class="button-container">
      <f7-button color="main-btn"
                 @click="sendSelfie"
                 :disabled="disabledBtn"
                 :loading="loading"
                 preloader
                preloader-size="26"
      >Начать выполнение</f7-button>
    </div>
  </f7-page>
</template>

<script>
import PhotoBlock from "@/components/PhotoBlock";
import {f7} from "framework7-vue"
export default {
  name: "Selfie",
  props: {
    f7route: Object
  },
  components: {PhotoBlock},
  data() {
    return {
      url: '',
      disabledBtn: true,
      loading: false
    }
  },
  methods: {
    sendSelfie() {
      this.loading = true
      let photos = [{title: 'selfie', url: this.url}];
      photos = JSON.stringify(photos)
      this.$server.request(`orders/attachPhotos/${this.f7route.params.id}`, {photos: photos}, ()=>{
        this.$server.request(`orders/setProgress/${this.f7route.params.id}`, {progress: 2},()=>{
          this.loading = false
          f7.views.current.router.navigate(`/order/${this.f7route.params.id}/0/timer`)
          f7.progressbar.set('.order-progress', 50)
        }, ()=>this.loading = false)
      })
    }
  },
  created() {
    this.$eventBus.on('upload-selfie', (data)=>{
      this.url = data
      this.disabledBtn = false
    })
  },
}
</script>

<style scoped>

</style>
