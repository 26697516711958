<template>
  <f7-page no-toolbar
           :page-content="false"
           @page:init="init"
  >
    <NavbarPage title="Полная статистика"/>
    <f7-page-content class="ptr-content ptr-content-balance-full-stat">
      <div class="ptr-preloader">
        <div class="preloader"></div>
        <div class="ptr-arrow"></div>
      </div>
      <f7-list v-if="Object.keys(rows).length"
             no-hairlines
             no-hairlines-between
             class="list-fullstat"
    >
      <f7-list-item
          class="row-fullstat"
          :class="{
                'plus-sum': parseInt(row.sum) > 0,
                'minus-sum': parseInt(row.sum) < 0,
          }"
          v-for="(row, i) in rows"
          :key="i"
          :header="row.sum + ' ' + $currency"
          :title="$store.state.systemParams.paymentTypes[row.type]"
          :footer="row.comment"
          :after="`${row.date.split('-')[2] }.${row.date.split('-')[1]}`"
      />
    </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
import NavbarPage from "@/components/NavbarPage";
import {f7} from "framework7-vue";

export default {
  name: "BalanceFullStat",
  components: {NavbarPage},
  props: {
    f7route: Object,
  },
  data() {
    return {
      rows: {}
    }
  },
  methods: {
    get(ptr = null) {
      this.$server.request('user/balance', {month: this.f7route.params.month}, (data) => {
        this.rows = data.response.rows
        if (ptr) ptr.done()
      }, ()=>{
        if (ptr) ptr.done()
      })
    },
    init() {
      let ptr = f7.ptr.get('.ptr-content-balance-full-stat')
      ptr.on('pullEnd', ()=>this.get(ptr))
    }
  },
  mounted() {
    this.get()
  }
}
</script>

<style lang="scss">
.row-fullstat {
  padding: 24px 0 16px;
  border-bottom: 1px solid var(--element-color);
  &.plus-sum {
    .item-title .item-header {
      color: var(--green-text-color);
    }
  }

  &.minus-sum {
    .item-title .item-header {
      color: var(--red-text-color);
    }
  }
  .item-title {
    font-size: 18px;
    line-height: 18px;

    .item-header {
      margin-bottom: 8px;
      font-size: 14px;
      color: var(--sub-text-color);
    }

    .item-footer {
      margin-top: 8px;
    }
  }

  .item-after {
    color: var(--f7-text-color);
    line-height: 18px;
  }
}
</style>
