import {createStore} from 'framework7/bundle';
import {f7} from "framework7-vue/bundle";
import {Server} from "@/server";
import eventBus from "@/eventBus";

const store = createStore({
    state: {
        systemParams: {},
        user: {},
        order: {},
        vocationDelay: 3,
        offices: []
    },
    actions: {
        set({state}, params) {
            for (let i in params) state[i] = params[i];
        },

    },
    getters: {
        user: ({state}) => state.user,
        systemParams: ({state}) => state.systemParams,
        userLoaded: ({state}) => state.userLoaded,
        offices: ({state})=> state.offices
    },

})

export default store;
