<template>
  <f7-page>
    <f7-navbar no-hairline class="phone-navbar">
      <f7-nav-left back-link/>
      <f7-nav-title></f7-nav-title>
    </f7-navbar>
    <f7-block-title class="auth-title">Ваш мобильный номер телефона</f7-block-title>
    <p class="auth-subtitle">Введите ваш номер телефона</p>
    <f7-input
        input-id="input_phone"
        ref="input_phone"
        outline
        @input="phone = $event.target.value; show_error_msg = false"
        placeholder="Номер телефона"
        inputmode="tel"
        autocomplete="off"
        type="text"
        :error-message="error_msg ? error_msg : 'Введите номер телефона'"
        :error-message-force="show_error_msg"
        v-maska="$store.state.systemParams.phone_mask"
    ></f7-input>
    <div class="button-container">
      <f7-button color="main-btn"
                 :loading="loading_phone"
                 preloader
                 preloader-size="26"
                 fill
                 @click="getCode"
                 type="submit"
      >Продолжить
      </f7-button>
    </div>
  </f7-page>
</template>
<script>
import {f7} from 'framework7-vue/bundle'

export default {
  name: "Phone",
  props: {
    f7route: Object
  },
  data() {
    return {
      phone: '',
      loading_phone: false,
      error_msg: '',
      show_error_msg: false,
    }
  },
  methods: {
    getCode() {
      this.loading_phone = true
      this.$server.request(`auth/login`, {phone: this.phone, office: this.f7route.params.office}, (data) => {
        f7.views.current.router.navigate(`/auth/code/${data.response}/${this.phone}/${this.f7route.params.office}`)
      }, (e) => {
        this.show_error_msg = true
        this.error_msg = e.error
      }, ()=>this.loading_phone = false)
    },
  },
  mounted() {
    this.$refs.input_phone.$el.querySelector('#input_phone').focus()
  }
}
</script>

<style scoped lang="scss">

</style>
